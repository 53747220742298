<script>
/**
 * Revenue Analytics component
 */
export default {
  props:{
    chartData: Object,
  },
  data() {
    return {
      componentKey: 0,
      series: [
        {name:"", type:"column", data:[]},
        {name:"", type:"line", data:[]}
      ],
      chartOptions: {
        chart: {
          height: 280,
          type: "line",
          toolbar: {
            show: true
          }
        },
        stroke: {
          width: [0, 3],
          curve: "smooth"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20%"
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: true
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: []
      }
    };
  },
  watch:{
    "chartData": function(newValue){
      this.series[0] = newValue.columns;
      this.series[1] = newValue.line;

      this.chartOptions.labels = newValue.labels;
      this.componentKey ++;
    }
  },
  mounted(){
    this.series[0] = this.chartData.columns;
    this.series[1] = this.chartData.line;

    this.chartOptions.labels = this.chartData.labels;
    this.componentKey ++;
    
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="float-right d-none d-md-inline-block">
        <div class="btn-group mb-2">
          <!--
          <button type="button" class="btn btn-sm btn-light">Today</button>
          <button type="button" class="btn btn-sm btn-light active">Weekly</button>
          <button type="button" class="btn btn-sm btn-light">Monthly</button>
          -->
        </div>
      </div>
      <h4 class="card-title mb-4">Sales vs Expenses</h4>
      <div>
        <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
        <apexchart
          :key="componentKey"
          class="apex-charts"
          height="280"
          dir="ltr"
          :series="series"
          :options="chartOptions"
        ></apexchart>
      </div>
    </div>

    <div class="card-body border-top text-center">
      <div class="row">
        <div class="col-sm-12 text-center">
            <div class="d-inline-flex">
              <h5 class="mr-2">FROM: {{chartData.initialDate}}</h5>
              <h5 class="ml-2 mr-2">TO: {{chartData.finalDate}}</h5>
            </div>
        </div>
        <!--
        <div class="col-sm-4">
          <div class="d-inline-flex">
            <h5 class="mr-2">$12,253</h5>
            <div class="text-success">
              <i class="mdi mdi-menu-up font-size-14"></i>2.2 %
            </div>
          </div>
          <p class="text-muted text-truncate mb-0">This month</p>
        </div>

        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i> This Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0 mr-2">$ 34,254</h5>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"></i>2.1 %
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="mt-4 mt-sm-0">
            <p class="mb-2 text-muted text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i> Previous Year :
            </p>
            <div class="d-inline-flex">
              <h5 class="mb-0">$ 32,695</h5>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>