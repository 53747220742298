<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Status from "./status";
import Chart from "./chart";

import Swal from "sweetalert2";
import axios from 'axios';


/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Status,
    Chart
  },
  props:{
    rights: Array
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Finance"
        },
        {
          text: "Dashboard",
          active: true
        }
      ],

      chartData:     {
        columns:{name:"", type:"column", data:[]},
        line:{name:"", type:"line", data:[]},
        labels:[],
        initialDate: "",
        finalDate:""
      },

      statusKey: 0,
      componentKey: 0,
      daterange:    "",
      initialDate:  "",
      finalDate:    "",
      validToken:   true,
      validAccess:  true,
    };
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    },
    "rights": function(newvalue, oldvalue){
      if(oldvalue !== newvalue){
        if(!newvalue.includes(this.user.user.id_role)){
          this.validAccess = false;
        }else{
          this.validAccess = true;
        }
      }
    }
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }

    this.resetDates();
  },
  mounted(){
    //Getting the information for the scores
    this.getRangeDates();
  },
  methods:{
    resetDates(){
      //Setting current year, month and the last day of current month
      let year        = new Date().getFullYear();
      let month       = new Date().getMonth();
      let finalDay    = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();

      //Set daterange with full first date of current month and last date of current month
      this.daterange  = [ new Date(year, month), new Date(year, month, finalDay) ];

      this.getRangeDates();
    },

    getRangeDates(){
      let diffTime;
      let diffDays;

      //Calculating the time between initial and final date.
      diffTime = this.daterange[1] - this.daterange[0];
      //Calculating the amount of days between initial and final date
      diffDays = Math.round(diffTime / (1000 * 3600 * 24) );

      this.initialDate  = this.daterange[0].toString().replace(" GMT-0700 (Pacific Daylight Time)", "");
      this.finalDate    = this.daterange[1].toString().replace(" GMT-0700 (Pacific Daylight Time)", "");
      this.finalDate    = this.daterange[1].toString().replace(" GMT-0800 (Pacific Standard Time)", "");

      if(diffDays <= 31){
        let app = this;
        this.statusKey ++;

        Swal.fire({
          title: "Loading data",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });
        
        axios
        .get(
          window.serverPath+'dist/mvw/controllers/workorders.php',
          {
            params: {
              request: 'getWorkordersUtilities',
              token: this.user.token,
              initialDate: this.initialDate,
              finalDate: this.finalDate
            }
          }
        )
        .then(response => {
          if( response.status == 200 ){
            if( response.data == 'Invalid token' ){
              this.validToken = false;
            }else{
              app.chartData = response.data;

              for( let i=0; i<app.chartData.line.data.length; i++){
                app.chartData.line.data[i] = parseFloat(app.chartData.line.data[i]).toFixed(2);
              }
              app.componentKey ++;
              Swal.close();
            }
          }else{
            Swal.fire("Ooops!", "It was not possible to load Companies! Please load this module again.", "error");
          }
        });
      }else{
        Swal.fire("Ooops", "Please choose a range date no longer than 31 days", "error");
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3">
        <div class="form-group mb-3">
          <label>Date Range</label>
          <br />
          <date-picker v-model="daterange" range append-to-body lang="en" type="data" value-type="date" format="YYYY-MM-DD" confirm @change="getRangeDates()"></date-picker>
        </div>
      </div>
      <div class="col-xl-2<">
        <div class="form-group mt-4 ml-2 pl-1 pt-2">
          <button type="button" class="btn btn-sm btn-dark" @click="resetDates"><i class="fas fa-redo"></i> RESET</button>
        </div>
      </div>
    </div>
    <div class="row" :key="componentKey">
      <div class="col-xl-3">
        <Status :user="user" :initialDate="initialDate" :finalDate="finalDate"/>
        <!--<EarningReport />-->
        <!--<SalesAnalytics />-->
      </div>
      <div class="col-xl-9" >
        <Chart :chartData="chartData"/>
      </div>
    </div>
  </Layout>
</template>